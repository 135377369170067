<template>
	<div class="container" v-if="pageShow"><div v-html="content"></div></div>
</template>

<script>
export default {
	data() {
		return {
			pageShow: false,
			content: ''
		};
	},
	async created() {
		let res = await this.$service.post('auth/privacy');
		this.content = res.data.introduce;
		this.pageShow = true;
	}
};
</script>

<style scoped>
.container {
	padding: 12px;
	box-sizing: border-box;
}
</style>
